<template>
    <div class="pr_block">
        <!-- replaced transition with a div -->
        <div>
            <swiper
            :modules="[SwiperAutoplay]"
            :loop="true"
            :loopAdditionalSlides="0"
            :autoplay="{
                delay: 2500,
                disableOnInteraction: false,
              }"
            :breakpoints="{
                365: {
                    slidesPerView: 2,
                    slidesPerGroup: 1,
                    centeredSlides : true,
                },
                512: {
                    slidesPerView: 3,
                    slidesPerGroup: 1,
                    centeredSlides : true,
                },
                993 : {
                    slidesPerView: 6,
                    slidesPerGroup: 1,
                },
            }"
            >
                <swiper-slide>
                    <div class="slide_box">
                    <a href="https://www.pdxmonthly.com/sponsored/2022/04/friendly-diamonds-custom-engagement-rings-online" target="_blank" rel="nofollow noopener">
                        <img loading="lazy" width="160" height="74" src="~/assets/home/prblog/pr1.svg" alt="portland">
                    </a>
                    </div>
                </swiper-slide>
                <swiper-slide> 
                    <div class="slide_box">
                    <a href="https://www.ibtimes.com/sustainability-fore-lab-diamond-upward-trend-3492644" target="_blank" rel="nofollow noopener">
                        <img loading="lazy" width="160" height="74" src="~/assets/home/prblog/pr2.svg" alt="internation business times">
                    </a>
                    </div>
                </swiper-slide>
                <swiper-slide> 
                    <div class="slide_box">
                    <a href="https://www.weddingforward.com/bridal-sets/" target="_blank" rel="nofollow noopener">
                        <img loading="lazy" width="160" height="74" src="~/assets/home/prblog/pr3.svg" alt="wedding forward">
                    </a>
                    </div>
                </swiper-slide>
                <!-- <swiper-slide> 
                    <div class="slide_box">
                    <a href="https://www.laweekly.com/a-dream-proposal-with-an-eco-friendly-twist/" target="_blank" rel="nofollow noopener">
                        <img loading="lazy" width="160" height="74" src="~/assets/home/prblog/pr4.svg" alt="laweekly">
                    </a>
                    </div>
                </swiper-slide> -->
                <swiper-slide>
                    <div class="slide_box">
                    <a href="https://www.weddingchicks.com/blog/how-to-make-your-engagement-ring-selfie-total-perfection-l-20099-l-47.html" target="_blank" rel="nofollow noopener">
                        <img loading="lazy" width="160" height="74" src="~/assets/home/prblog/pr5.svg" alt="wedding chicks">
                    </a>
                    </div>
                </swiper-slide>
                <swiper-slide>   
                    <div class="slide_box">
                    <a href="https://www.forbes.com/sites/joanneshurvell/2022/06/23/3-key-sustainable-fashion-trends-to-follow-this-year/" target="_blank" rel="nofollow noopener">
                        <img loading="lazy" width="160" height="74" src="~/assets/home/prblog/pr6.svg" alt="forbes">
                    </a>
                    </div>
                </swiper-slide>
                <swiper-slide> 
                    <div class="slide_box">
                    <a href="https://www.theknot.com/marketplace/friendly-diamonds-new-york-ny-2048281" target="_blank" rel="nofollow noopener">
                        <img loading="lazy" width="160" height="74" src="~/assets/home/prblog/pr7.svg" alt="the knot">
                    </a>
                    </div>
                </swiper-slide>
                <!-- <swiper-slide>  
                    <div class="slide_box">
                    <a href="https://www.brides.com/lab-grown-diamond-tennis-bracelet-5215874" target="_blank" rel="nofollow noopener">
                        <img loading="lazy" width="160" height="74" src="~/assets/home/prblog/pr8.svg" alt="brides">
                    </a>
                    </div>
                </swiper-slide> -->
                <swiper-slide>
                    <div class="slide_box">
                        <a href="https://www.latintimes.com/friendly-diamonds-your-perfect-match-514765" target="_blank" rel="nofollow noopener">
                            <img loading="lazy" width="160" height="74" src="~/assets/home/prblog/pr9.svg" alt="latin times">
                        </a>
                    </div>
                </swiper-slide>
                <swiper-slide> 
                    <div class="slide_box">
                    <a href="https://www.luxuo.com/style/jewelry/a-friendly-approach-to-diamond-engagement-rings.html" target="_blank" rel="nofollow noopener">
                        <img loading="lazy" width="160" height="74" src="~/assets/home/prblog/pr10.svg" alt="luxuo">
                    </a>
                    </div>
                </swiper-slide>
                <!-- <swiper-slide>
                    <div class="slide_box">
                        <a href="https://www.weddingwire.com/cat-DressList.php?type=10&amp;designer=4499" target="_blank" rel="nofollow noopener">
                            <img loading="lazy" width="160" height="74" src="~/assets/home/prblog/pr11.svg" alt="wedding wire">
                        </a>
                    </div>
                </swiper-slide> -->
                <!-- <swiper-slide>
                    <div class="slide_box">
                        <a href="https://thevou.com/fashion/engagement-rings/" target="_blank" rel="nofollow noopener">
                            <img loading="lazy" width="160" height="74" src="~/assets/home/prblog/pr12.svg" alt="the vou">
                        </a>
                    </div>
                </swiper-slide> -->
            </swiper>
        </div>
    </div>
</template>

<script setup>

</script>


<style scoped>
.slide_box {
    padding: 0 2%;
    text-align: center;
}
button{
    background: #2a4437;
    color: #fff;
    border-color: #2a4437;
}

h4{
    font-size: 30px;

}
p{
    font-weight: 500;
    color: #6a6161;
}
img{
    
    transition: 0.8s;
}
.slide_box span {
    display: block;
    text-align: center;
    color: #000;
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 15px;
    font-weight: 600;
}
@media(max-width: 992px){
    p br{
        display: none;
    }
    .pr_block{
        padding: 4% 0;
    }
 
}
@media(max-width: 767px){
    .pr_block{
        padding: 7% 0;
    }
    .test_block {
        margin: 7% 0;
    }
    h4{
        font-size: 24px;
    }
   
}
</style>
