<template>
    <section class="shop_category">
        <transition name="fade"> 
            <div class="pt-4">
                <div>
                    <h4 v-if="page === 'cart'" class="mb-4 text-center font-active">Continue Shopping</h4>
                    <h4 v-else class="mb-4 text-center font-active">Shop By Category</h4>
                </div>
                <div>
                    <div v-if="!skeleton">
                        <swiper ref="c1"
                        :modules="[SwiperAutoplay]"
                        :loop="true"
                        :loopAdditionalSlides="0"
                        :autoplay="{
                            delay: 2500,
                            disableOnInteraction: false,
                          }"
                        :breakpoints="{
                            365: {
                                slidesPerView: 2,
                                slidesPerGroup: 1,
                                centeredSlides : true,
                            },
                            512: {
                                slidesPerView: 2,
                                slidesPerGroup: 1,
                                centeredSlides : false,
                                autoplay:{
                                    delay: 1500,
                                    disableOnInteraction: true,
                                  }
                            },
                            993 : {
                                slidesPerView: 5,
                                slidesPerGroup: 1,
                            },
                        }" 
                        class="mySwiper"
                          >
                            <swiper-slide>
                                <div class="slide_box">
                                    <NuxtLink :to="localePath({ name:'cyo-setting-list-ring'})">
                                        <div class="img_box radious_eight">
                                            <picture>
                                                <source width="761" height="761" type="image/webp" srcset="~/assets/home/category/ring.webp">
                                                <img loading="lazy" fluid width="761" height="761" type="image/jpeg" src="~/assets/home/category/ring.jpg" alt="Shop by Engagement Rings Category">
                                            </picture>
                                        </div>
                                        <span>Engagement Rings</span>
                                    </NuxtLink>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="slide_box">
                                    <NuxtLink :to="localePath({ name: 'shop-eternity'})">
                                        <div class="img_box radious_eight">
                                            <picture>
                                                <source width="761" height="761" type="image/webp" srcset="~/assets/home/category/eternity.webp">
                                                <img loading="lazy" type="image/jpeg" fluid width="761" height="761" src="~/assets/home/category/eternity.jpg" alt="Shop by Eternity Rings Category">
                                            </picture>
                                        </div>
                                        <span>Eternity Rings</span>
                                    </NuxtLink>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="slide_box">
                                    <NuxtLink  :to="localePath({ name: 'shop-bracelet'})">
                                        <div class="img_box radious_eight">
                                            <picture>
                                            <source width="761" height="761" type="image/webp" srcset="~/assets/home/category/bracelet.webp">
                                            <img loading="lazy" type="image/jpeg" fluid width="761" height="761" src="~/assets/home/category/bracelet.jpg" alt="Shop by Diamond Pendants Category">
                                            </picture> 
                                        </div>
                                        <span>Tennis Bracelets </span>   
                                    </NuxtLink>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="slide_box">
                                    <NuxtLink :to="localePath({ name: 'cyo-setting-list-pendant'})">
                                        <div class="img_box radious_eight">
                                            <picture>
                                            <source width="761" height="761" type="image/webp" srcset="~/assets/home/category/pendant.webp">
                                            <img loading="lazy" type="image/jpeg" fluid width="761" height="761" src="~/assets/home/category/pendant.jpg" alt="Shop by Tennis Bracelets Category">
                                            </picture>
                                        </div>
                                        <span>Diamond Pendants</span>
                                    </NuxtLink>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="slide_box">
                                    <NuxtLink :to="localePath({ name: 'shop-hoop'})">
                                        <div class="img_box radious_eight">
                                            <picture>
                                            <source width="761" height="761" type="image/webp" srcset="~/assets/home/category/earring.webp">
                                            <img loading="lazy" type="image/jpeg" fluid width="761" height="761" src="~/assets/home/category/earring.jpg" alt="Shop by Hoop Earrings Category">
                                            </picture>
                                        </div>
                                        <span>Hoop Earrings</span>
                                    </NuxtLink>
                                </div>
                            </swiper-slide>

                            <!-- <swiper-slide>
                                <div class="slide_box">
                                    <NuxtLink :to="localePath({ name:'cyo-setting-list-ring'})">
                                        <div class="img_box">
                                            <picture>
                                                <source width="761" height="761" type="image/webp" srcset="~/assets/home/category/ring.webp">
                                                <img loading="lazy" fluid width="761" height="761" type="image/jpeg" src="~/assets/home/category/ring.jpg" alt="Shop by Engagement Rings Category">
                                            </picture>
                                        </div>
                                        <span>Engagement Rings</span>
                                    </NuxtLink>
                                </div>
                            </swiper-slide> -->
                        </swiper>
                    </div>

                    <div class="container" v-else>
                        <div class="row for_desktop">
                            <div class="col-md-3" v-for="index in 4" :key="index">
                                <div class="prod_block">
                                    <div class="prod_image skeleton_lg">
                                        <img width="200" loading="lazy" height="200" alt="loader" src="~/assets/assets/loader.svg" />
                                    </div>
                                    <div class="prod_image skeleton_md">
                                        <!-- <img width="200" loading="lazy" height="200" alt="loader" src="~/assets/assets/loader.svg" /> -->
                                    </div>
                                    <!-- <img width="200" loading="lazy" height="200" alt="loader" src="~/assets/assets/loader.svg" /> -->
                                </div>
                            </div>
                        </div>
                        <div class="for_mobile text-center">
                            <div class="prod_block">
                                <div class="prod_image">
                                    <img width="200" loading="lazy" height="200" alt="loader" src="~/assets/assets/loader.svg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>            
    </section>
</template>

<script setup>

const localePath = useLocalePath()
const props = defineProps({
  locale: String,
  page: String
})
const skeleton = ref(true);

const showNext = () => {
    $refs.c1.prev();
};

const showPrev = () => {
    $refs.c1.next();
};

onMounted(() => {
    nextTick(() => skeleton.value = false);
  
    // skeleton.value = false;
});
</script>

<style scoped>
section.shop_category {
    padding: 0 4%;
}
.transform-enter-active,
.transform-leave-active {
    transition: transform 0.5s linear;
    transform: translateY(-20px);
}

.transform-enter,
.transform-leave-to {
    transform: translateY(0px);

}

.slide_box{
    text-align: center;
    padding: 2%;
}


h4{
    font-size: 30px;
}
p{
    font-weight: 500;
    color: #000;
}
a{
    display: inline-block;
}
span{
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    display: block;
    text-align: center;
    color: #000;
    padding-top: 5%;
    letter-spacing: 3px;
}

@media(max-width: 1400px){
   
    span{
        font-size: 16px;
        letter-spacing: 2px;
    }
}
@media(max-width: 992px){
    span{
        font-size: 14px;
    }
}
@media(max-width: 767px){
    section.shop_category{
        padding: 0;
    }
    h4{
        font-size: 24px;
    }
    p{
        font-size: 16px;
        padding-left: 5%;
        padding-right: 5%;
    }
}


/* --------animation---------- */
.img_box{
    overflow: hidden;
    max-width: 100%;
    max-height: 100%;
}
img{
    transition: 0.8s; 
    /* image-rendering: pixelated; */
}
.img_box img:hover{
    transform: scale(1.2);
    transition: 0.8s;
}
.slide_box:hover span{
    color: var(--green);
}
/* --------animation---------- */
</style>